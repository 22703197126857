import { useRef, useState } from "react";
import Tooltip from "../../../../utils/Tooltip";
import { useHover } from "@uidotdev/usehooks";
import clsx from "clsx";

function HeaderSocialNetwork(props){
    const [focus,updateFocus] = useState(false);
    const [ref, hover] = useHover();
    return (
        <a href={props.link} aria-label={props.label} className={clsx(
            "relative",
            props.reverse ? "ml-4" : "mr-4"
        )} ref={ref} onFocus={()=>{
            updateFocus(true);
        }} onBlur={()=>{
            updateFocus(false);
        }}
        target="_blank">
            <img src={`/images/icons/brands/${props.icon}.svg`} className="w-[2rem]"/>
            <Tooltip label={props.name} show={hover || focus}/>
        </a>
    )
}

export default HeaderSocialNetwork;