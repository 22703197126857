import { useGSAP } from "@gsap/react"
import gsap from "gsap"
import { TextPlugin } from "gsap/all";
import { useEffect, useRef, useState } from "react";

gsap.registerPlugin(useGSAP, TextPlugin);

export default function Loader({ loading, setLoader, loadingAnimation, setLoadingAnimation }) {
    const container = useRef();
    const image = useRef();
    const text = useRef();

    useGSAP(() => {
        const timeline = gsap.timeline();
        timeline.from(container.current, {
            duration: 0.5,
            yPercent: -100
        }).from(image.current, {
            duration: 0.75,
            scale: 0,
            ease: "bounce"
        }).to(text.current, {
            delay: 0.5,
            duration: 1,
            text: "Chargement ...",
            onComplete: () => {
                setLoadingAnimation(false);
            }
        })
    }, []);

    useEffect(()=>{
       if(!loading && !loadingAnimation){
        window.scrollTo({
            left: 0,
            top: 0,
        });
        gsap.to(container.current,{
            duration: 0.5,
            yPercent: -100
        });
        gsap.to(image.current,{
            duration: 0.75,
            scale: 0,
            ease: "bounce", 
            onComplete: ()=>{
                setLoader(false);
                setLoadingAnimation(true);
            }
        });
        gsap.to(text.current,{
            duration: 1,
            text: ""
        });
       }
    },[loading, loadingAnimation]);


    return (
        <div className="fixed top-0 left-0 w-full h-full z-50 bg-yanisallali-black flex justify-center items-center" ref={container}>
            <div className="flex flex-col items-center relative pb-6">
                <img src="/images/logo/logo_white.png" alt="Logo" className="max-w-[300px]" ref={image} />
                <div ref={text} className="text-yanisallali-white font-bold text-larger absolute top-full left-1/2 -translate-x-1/2 whitespace-nowrap"></div>
            </div>
        </div>
    )
}