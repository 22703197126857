import GrayBox from "../../../../boxes/GrayBox";
import Heading from "../../../../utils/texts/Heading";

function ContactBox(props){
    return (
        <GrayBox content={
            <div className="w-full h-full flex flex-col">
                <h3>
                    <Heading
                    content={props.heading}
                    fontSize={"text-semi-large tablet:text-thick"}
                    />
                </h3>
                <p className="tablet:text-large">{props.subtitle}</p>
                <a href={props.url} aria-label={props.label} className="text-more font-bold tablet:text-thick relative block after:content-[''] w-fit after:w-full after:h-[2px] after:bg-yanisallali-white after:absolute after:bottom-0 after:left-0 after:transition-all after:scale-x-0 hover:after:scale-x-100 after:origin-left">{props.label}</a>
                {props.content}
            </div>
        }
        className={props.className}/>
    )
}

export default ContactBox;