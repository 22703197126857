import { useEffect, useState } from "react";
import Heading from "../../utils/texts/Heading";
import ArrowScrollAnimation from "../Temporary/LandingSection/ArrowScrollAnimation";
import axios from "axios";
import { BASE_URL } from "../../../settings";
import PortfolioCategories from "./PortfolioCategories";
import PortfolioGrid from "./PortfolioGrid";
import ContactSection from "../Temporary/ContactSection";
import { ClipLoader } from "react-spinners";
import clsx from "clsx";
import { tr } from "date-fns/locale";
import { useLocation } from "react-router-dom";
export default function Portfolio({setLoading}) {
    const location = useLocation();
    const [categories,setCategories] = useState([]);
    const [items,setItems] = useState([]);
    const [category,setCategory] = useState((location.state && location.state.category) ? location.state.category : null);
    const [itemsLoading,setItemsLoading] = useState(true);

    async function getCategories(){
        try{
            const response = await axios.get(`${BASE_URL}/categories_realisation`);
            setCategories(response.data);
            setLoading(false);
        } catch(err){
            console.log(err);
        }
    }

    async function getItems(){
        const url = `${BASE_URL}/realisations${category ? "?categories_realisation=" + category.id : ""}`;
        try{
            const response = await axios.get(url);
            setItems(response.data);
        } catch(err){
            console.log(err);
        } finally{
            setItemsLoading(false);
        }
    }

    useEffect(()=>{
        getCategories();
    },[]);

    useEffect(()=>{
        setItemsLoading(true);
        getItems();
    },[category]);
    

    return (
        <div className="mt-8">
            <div className="flex flex-col items-center text-center mb-16">
                <div className="font-bold text-thick tablet:text-larger mb-6">Re ! 👋</div>
                <h1>
                    <Heading
                        content={[
                            {
                                text: "Découvrez mon "
                            },
                            {
                                text: "Portfolio",
                                highlight: true
                            },
                            {
                                text: " !"
                            }
                        ]}
                        fontSize={"text-larger tablet:text-extra-large"} />
                </h1>
                <p className="text-semi-more tablet:text-large mb-8 text-pretty max-w-[750px] mt-6">Explorez une sélection de mes projets les plus marquants ✨, reflétant mes compétences diversifiées 🛠️ et mon expertise approfondie dans plusieurs domaines 🎯.</p>
            </div>
            <div>
                <div className="flex flex-col items-center mb-8">
                    <h2 className="font-bold text-thick large-computer:text-larger">Triez par catégorie</h2>
                    <ArrowScrollAnimation />
                </div>
                <PortfolioCategories categories={categories} setCategory={setCategory} setAll={()=>{
                    setCategory(null);
                }} category={category}/>
            </div>
            <div className={clsx(
                "relative",
                itemsLoading ? "mt-12" : "mt-24"
            )}>
                {itemsLoading ? <ClipLoader color="#FFFFFF" className="absolute top-0 left-1/2 -translate-x-1/2"/> : <PortfolioGrid items={items}/>}
            </div>
            <div className="mt-32">
             <h2 className="font-bold text-thick large-computer:text-larger text-center">Me contacter</h2>
             <ContactSection/>
            </div>
        </div>
    )
}