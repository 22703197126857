import clsx from "clsx";

function Tooltip(props){
    return (
        <div className={clsx(
            "bg-yanisallali-magenta p-1.5 absolute top-full left-1/2 -translate-x-1/2 whitespace-nowrap mt-[0.5rem] duration-300",
            props.show ? "opacity-100" : "opacity-0",
            "transition-all rounded after:content-[''] after:absolute after:block after:w-[0px] after:left-1/2 after:top-0 after:border-[0.5rem] after:border-transparent after:border-b-yanisallali-magenta after:-translate-y-full after:-translate-x-1/2"
        )}>
            {props.label}
        </div>
    )
}

export default Tooltip;