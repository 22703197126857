import clsx from "clsx";
import CtaGridItem from "./CtaGridItem";

export default function CtaGrid({links, className}){
    return (
        <div className={clsx(
            "grid grid-cols-1 computer:grid-cols-3 gap-8",
            className
        )}>
            {links.map(
                v=><CtaGridItem {...v.cta}/>
            )}
        </div>
    )
}