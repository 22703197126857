import GrayBox from "../../../boxes/GrayBox";
import HeaderSocialNetworks from "../../../header/CustomNavBar/HeaderSocialNetworks";
import Heading from "../../../utils/texts/Heading";
import ConfirmPopup from "./ConfirmPopup";
import ContactBox from "./ContactBox";
import ContactForm from "./ContactForm";
import { useState } from "react";
function ContactSection(props){
    const [popup,setPopup] = useState(false);
    return (
        <>
        <div className="computer:flex mt-16 items-stretch max-w-[1300px] mx-auto">
            <div className="computer:mr-6 flex flex-col">
                <ContactBox heading={[
                    {
                        text:"Par "
                    },
                    {
                        text:"Téléphone",
                        highlight: true
                    }
                ]}
                subtitle="Du Lundi au Vendredi de 9h à 19h"
                label="06 51 30 66 59"
                url="tel:+33651306659"
                className="mb-6 computer:max-w-[500px]"/>
                <ContactBox heading={[
                    {
                        text:"Par "
                    },
                    {
                        text:"Mail",
                        highlight: true
                    }
                ]}
                subtitle="Veillez bien à mettre votre sujet dans l'objet de l'email."
                label="contact@yanisallali.com"
                url="mailto:contact@yanisallali.com"
                className="mb-6 computer:max-w-[500px]"/>
                <ContactBox heading={[
                    {
                        text:"Sur "
                    },
                    {
                        text:"mes réseaux",
                        highlight: true
                    }
                ]}
                content={
                    <HeaderSocialNetworks className="mt-3"/>
                }
                className="mb-6 computer:mb-0 computer:max-w-[500px] grow"
                />
            </div>
            <div className="grow">
                <ContactBox heading={[
                        {
                            text:"Formulaire",
                            highlight:true
                        },
                        {
                            text:" de contact",
                        }
                    ]}
                    content={
                        <ContactForm api={props.api} onSuccess={()=>{
                            setPopup(true);
                        }}/>
                    }
                    className="w-full h-full"
                    />
            </div>
        </div>
       {popup &&  <ConfirmPopup onClose={()=>{
            setPopup(false);
        }}/>}
        </>
    )
}

export default ContactSection;