import clsx from "clsx";
import PortfolioGridItem from "./PortfolioGridItem";

export default function PortfolioGrid({items, className, ...props}){
    return (
        <div className={clsx(
            "max-w-[1300px] mx-auto grid grid-cols-1 tablet:grid-cols-2 computer:grid-cols-3 gap-x-6 gap-y-8 computer:gap-y-0",
            className
        )}>
            {items.map(
                (v,index)=><PortfolioGridItem key={index} {...v}/>
            )}
        </div>
    )
}