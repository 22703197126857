import { useEffect } from "react";
import ContactSection from "./ContactSection";
import EndSection from "./EndSection";
import LandingSection from "./LandingSection";

function Temporary({isMobile, api, setLoading}) {

    useEffect(()=>{
        setLoading(false);
    },[]);

    return (
        <div className="tablet:mt-16">
            <LandingSection />
            <ContactSection api={api} />
            <EndSection
                isMobile={isMobile}
            />
        </div>
    )
}

export default Temporary;