import CtaButton from "../../../buttons/CtaButton";
import DecorativeButton from "../../../buttons/DecorativeButton";
import Heading from "../../../utils/texts/Heading";
import ArrowScrollAnimation from "./ArrowScrollAnimation";

function LandingSection(props) {
    return (
        <div className="flex flex-col items-center large-computer:items-start large-computer:flex-row w-fit mx-auto relative">
            <div className="flex flex-col items-center text-center large-computer:text-left large-computer:block large-computer:ml-32 large-computer:mt-16 max-w-[850px]">
                <div className="font-bold text-thick tablet:text-larger mb-6">Salut ! 👋</div>
                <div className="mb-6 text-pretty w-fit">
                    <Heading
                        content={[
                            {
                                text: "Le site",
                                highlight: true
                            },
                            {
                                text: " se refait une beauté pour "
                            },
                            {
                                text: "la rentrée.",
                                highlight: true
                            }
                        ]}
                        fontSize={"text-larger tablet:text-extra-large"} />
                </div>
                <p className="text-semi-more tablet:text-large mb-8 text-pretty max-w-[450px]">Mais attention ! Tu peux toujours explorer mes travaux et mes projets. 🎨🖌️</p>
                <div className="mb-32">
                    <CtaButton
                    label={"Voir le Portfolio"}$
                    url={"/portfolio"}
                    className={"w-fit"}
                    iconPath={"/images/icons/Layers.svg"}/>
                </div>
            </div>
            <img src="/images/Photo_Yans.png" className="max-w-[700px] hidden min-[1500px]:block grayscale object-contain object-top" />
            <div className="large-computer:absolute bottom-0 left-1/2 large-computer:-translate-x-1/2 flex flex-col items-center">
                <h2 className="font-bold text-thick large-computer:text-larger">Me contacter ?</h2>
                <ArrowScrollAnimation />
            </div>
        </div>
    )
}

export default LandingSection;