import { useHover } from "@uidotdev/usehooks";
import clsx from "clsx";

function SubmitButton(props){
    return (
        <button type="submit" aria-label={props.label} className={clsx(
            "flex items-center justify-center bg-yanisallali-magenta font-bold rounded-3xl px-5 py-2 focus-visible:outline-none focus-visible:border-yanisallali-white transition-all border-yanisallali-magenta border-[3px] hover:border-yanisallali-1/3-white active:bg-yanisallali-light-magenta active:border-yanisallali-1/3-white",
            props.className
        )}>
            <span>{props.label}</span>
            <img src={`/images/icons/${props.icon}.svg`} className={clsx(
                "w-[1rem] ml-2 transition-all",
            )}/>
        </button>
    )
}

export default SubmitButton;