import clsx from "clsx";

function Heading(props){
    function setTextsArray(content){
        if(!Array.isArray(content)){
            return [{
                "text":content
            }]
        } else {
            return content
        }
    }
    const texts = setTextsArray(props.content);
    return (
        <>
        {texts.map(
            v=><span className={clsx(
                props.fontSize,
                "font-bold relative z-30 leading-tight",
                v.highlight && "after:content-[''] after:w-[105%] after:h-[90%] after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:bg-yanisallali-magenta after:z-[-1] after:rotate-[-1deg] whitespace-nowrap",
            )}>{v.text}</span>
        )}
        </>
    )
}

export default Heading;