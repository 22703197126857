import { Link } from "react-router-dom";

function HeaderIcon(props){
    return (
        <Link to={"/"}>
            <img src="/images/logo/logo_white.png" alt="Logo" className="max-w-[120px]"/>
        </Link>
    )
}

export default HeaderIcon;