import { useNavigate } from "react-router-dom";
import CtaButton from "../../buttons/CtaButton";
import SingleButton from "../../buttons/SingleButton";
import Heading from "../../utils/texts/Heading";
import { useEffect } from "react";

export default function NotFound({setLoading}) {
    const navigate = useNavigate();

    useEffect(()=>{
        setLoading(false);
    },[]);

    return (
        <div className="w-dvw h-dvh fixed top-0 left-0 flex justify-center items-center">
            <div className="flex flex-col items-center">
                <div className="font-bold text-thick tablet:text-larger mb-10">Oups ! 😳</div>
                <div className="text-[10rem] tablet:text-[16rem] leading-[9rem] tablet:leading-[14rem] font-bold relative z-20 after:content-[''] after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:bg-yanisallali-magenta after:w-[105%] after:h-full after:-z-10 after:-rotate-[1deg]">
                    404
                </div>
                <div className="my-10 text-center">
                    <p className="text-semi-less tablet:text-large text-pretty max-w-[800px]">La page que tu recherche semble introuvable ...
                        <br />
                        Peut-être a-t-elle été déplacée ou supprimée.🤷‍♂️ </p>
                </div>
                <div className="w-full tablet:flex justify-center items-center">
                    <SingleButton
                    label={"Revenir en arrière"}
                    iconPath={"/images/icons/Arrow-left-from-line.svg"}
                    className={"w-full mb-4 tablet:mb-0 tablet:w-fit tablet:mx-2"}
                    onClick={()=>{
                        navigate(-1);
                    }}/>
                    <CtaButton
                    label={"Accueil"}
                    iconPath={"/images/icons/Home.svg"}
                    url={'/'}
                    className={"bg-yanisallali-white text-yanisallali-magenta [&_path]:stroke-yanisallali-magenta tablet:mx-2 hover:px-8"}/>
                </div>
            </div>
        </div>
    )
}