import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { useRef } from "react";
gsap.registerPlugin(useGSAP);
function ArrowScrollAnimation(props){
    const ref = useRef();
    useGSAP(()=>{
        const timeline = gsap.timeline({
            repeat: -1,
        });
        timeline.to(ref.current,{
            duration: 1.5,
            y: 15
        });
        timeline.to(ref.current,{
            duration: 1.5,
            y: 0,
        })
    },[]);
    return (
        <img src="/images/icons/Chevron-down.svg" className="w-[40px]" alt="Flèche" aria-hidden ref={ref}/>
    )
}

export default ArrowScrollAnimation;