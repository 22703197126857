import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import GrayBox from "../../../../boxes/GrayBox";
import Heading from "../../../../utils/texts/Heading";
import { useRef } from "react";
gsap.registerPlugin(useGSAP);

function ConfirmPopup(props) {
    const ref = useRef();
    useGSAP(()=>{
        gsap.from(ref.current,{
            duration: 0.5,
            opacity:0
        })
    },[]);
    return (
        <div className="w-dvw h-dvh fixed top-0 left-0 bg-yanisallali-1/2-black z-50" ref={ref}>
            <GrayBox
                content={
                    <>
                    <div className="flex flex-col items-center text-center py-8 px-8 relative">
                        <img src="/images/icons/Check-circle.svg" alt="Check" aria-hidden className="w-[70px] mb-5" />
                        <p>
                            <Heading
                                content={[
                                    {
                                        text: "Ton message a bien été "
                                    },
                                    {
                                        text: "envoyé !",
                                        highlight: true
                                    }
                                ]}
                                fontSize="text-thick" />
                        </p>
                        <p className="text-large mt-3">Je te réponds rapidement.</p>
                    </div>
                    <button className="absolute right-[1rem] top-[1rem] w-[1.25rem] aspect-square before:content-[''] before:w-full before:h-[2px] before:bg-yanisallali-white before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:rotate-45 after:content-[''] after:w-full after:h-[2px] after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:bg-yanisallali-white after:-rotate-45 hover:after:bg-yanisallali-magenta hover:before:bg-yanisallali-magenta before:transition-all after:transition-all"
                    onClick={()=>{
                        gsap.to(ref.current,{
                            duration: 0.5,
                            opacity: 0,
                            onComplete : props.onClose
                        })
                    }}></button>
                    </>
                }
                className="w-fit absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
        </div>
    )
}

export default ConfirmPopup;