import Heading from "../../../../utils/texts/Heading";

export default function CtaGridItem({ link, icon, className }) {
    return (
        <a href={link.url} target={link.target} className="">
            <div className="bg-yanisallali-1/10-black border border-yanisallali-1/3-white py-8 rounded-xl hover:scale-105 transition-all">
                <div className="flex flex-col items-center">
                    <img src={icon.sizes.thumbnail} alt={icon.alt} width={50}/>
                    <div className="text-center mt-3">
                        <Heading
                        content={link.title}
                        fontSize="text-semi-large"/>
                    </div>
                </div>
            </div>
        </a>
    )
}