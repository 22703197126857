import clsx from "clsx";
import { useEffect, useRef } from "react";
import { themeColors } from "../../../settings";
import { ReactSVG } from "react-svg";
import { useHover } from "@uidotdev/usehooks";

function DecorativeButton({ className, label, iconPath, iconClassName}) {
    return (
        <div
            className={clsx(
                "flex items-center justify-center bg-yanisallali-magenta font-bold rounded-3xl px-5 py-2 transition-all",
                className
            )}
        >
            <span className="whitespace-nowrap">{label}</span>
            <ReactSVG
                src={iconPath} // Path to the SVG
                className={clsx(
                    "w-[1rem] ml-2 [&_path]:transition-all",
                    iconClassName
                )}
                alt="icon"
            />
        </div>
    );
}

export default DecorativeButton;
