
import clsx from "clsx";
import HeaderSocialNetwork from "./HeaderSocialNetwork";
function setSocialNetwork(label, link, icon, name){
    return {
        "label":label,
        "link":link,
        "icon":icon,
        "name":name
    }
}

function HeaderSocialNetworks(props){

    
    const socialNetworks = [
        setSocialNetwork("Instagram","https://www.instagram.com/yansi.all/","instagramvector","@yansi.all"),
        setSocialNetwork("Linkedin","https://www.linkedin.com/in/yanis-allali-8a58b4291/","linkedinvector","Yanis Allali"),
        setSocialNetwork("YouTube","https://www.youtube.com/channel/UC1xDKttzYjIypCzccMMmihg","youtubevector","Yanis Allali"),
        setSocialNetwork("TikTok","https://www.tiktok.com/@yanisallali.com","tiktokvector","@yanisallali.com"),
    ]

    if(props.email){
        socialNetworks.push(setSocialNetwork("Mail","mailto:contact@yanisallali.com","../Mail-1","contact@yanisallali.com"));
    }

    return (
        <div className={clsx(
            "items-center",
            props.responsive ? "hidden tablet:flex" : "flex",
            props.className
        )}>
            {socialNetworks.map(
                v=><HeaderSocialNetwork {...v} reverse={props.reverse}/>
            )}
        </div>
    )
}

export default HeaderSocialNetworks;