import Heading from "../../../utils/texts/Heading";

function EndSection(props){
    return (
        <div className="mt-48 flex flex-col tablet:flex-col-reverse">
            <div className="text-center leading-[2.2rem] tablet:leading-normal">
                <Heading
                content={props.isMobile ? [
                    {
                        text:"RDV en "
                    },
                    {
                        text:"Septembre !",
                        highlight:true
                    }
                ] : [
                    {
                        text: "RDV en Septembre !",
                        highlight:true
                    }
                ]}
                fontSize={"text-larger tablet:text-extra-large"}/>
            </div>
            <div className="text-thick tablet:text-larger font-bold text-center mt-3">
                Patience...⌛
            </div>
        </div>
    )
}

export default EndSection;