import HeaderIcon from "./HeaderIcon";
import HeaderSocialNetworks from "./HeaderSocialNetworks";

function CustomNavBar(props){
    return (
        <nav className="flex max-w-[1920px] w-full justify-center tablet:justify-between items-center mx-auto bg-yanisallali-1/10-black border border-yanisallali-1/3-white border-solid rounded-xl py-5 px-10 backdrop-blur relative z-50">
            <HeaderIcon/>
            <HeaderSocialNetworks responsive reverse email/>
        </nav>
    )
}

export default CustomNavBar;