import clsx from "clsx";

export default function OutilsGrid({ outils, className }) {
    return (
        <div className={clsx(
            "grid gap-8 justify-end",
            outils.length >= 3 && "grid-cols-3 max-w-[250px] ",
            outils.length === 2 && "grid-cols-2 max-w-[170px] ",
            outils.length === 1 && "grid-cols-1 max-w-[90px] ",
            className
        )}>
            {outils.map(
                v => <img src={v.src} alt={v.alt} />
            )}
        </div>
    )
}