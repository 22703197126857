import logo from './logo.svg';
import './App.css';
import CustomNavBar from './components/header/CustomNavBar';
import Temporary from './components/sections/Temporary';
import { useMediaQuery } from 'react-responsive';
import Credits from './components/footer/Credits';
import Texture from './components/Texture';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Portfolio from './components/sections/Portfolio';
import NotFound from './components/sections/404/NotFound';
import Project from './components/sections/Project';
import { useEffect, useState } from 'react';
import Loader from './components/Loader';
import { tr } from 'date-fns/locale';
import clsx from 'clsx';
import { match } from 'path-to-regexp';

function findRoute(routes = [], path = "") {
  return routes.find((v) => {
    const matcher = match(v.path, { decode: decodeURIComponent });
    return matcher(path) !== false;
  });
}


function App() {
  const isMobile = useMediaQuery({
    query: "(max-width: 500px)"
  });

  const location = useLocation();

  const [currentUrl, setCurrentUrl] = useState(location.pathname);

  const [loading, setLoading] = useState(true);

  const [loader, setLoader] = useState(true);

  const [loadingAnimation, setLoadingAnimation] = useState(true);


  const routes = [
    {
      "path": "/",
      "element": <Temporary
        isMobile={isMobile} setLoading={setLoading} />
    },
    {
      "path": "/portfolio",
      "element": <Portfolio setLoading={setLoading}/>
    },
    {
      "path": "/portfolio/projets/:slug",
      "element": <Project setLoading={setLoading}/>
    },
    {
      "path": "*",
      "element": <NotFound setLoading={setLoading}/>
    }
  ]

  const [route,setRoute] = useState(null)


  useEffect(() => {
    setLoading(true);
  }, [currentUrl]);

  useEffect(()=>{
    if(!loadingAnimation){
      const route = findRoute(routes,location.pathname);
      setRoute(route ? route : findRoute(routes,"*"));
    }
  },[loadingAnimation]);

  useEffect(()=>{
    if(loading){
      setLoadingAnimation(true);
      setLoader(true);
    }
  },[loading]);

  useEffect(()=>{
    if(location.pathname != currentUrl){
      setCurrentUrl(location.pathname);
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
  },[location]);


  return (
    <div className='bg-yanisallali-black min-w-screen min-h-dvh'>
      {loader && <Loader loading={loading} setLoader={setLoader} loadingAnimation={loadingAnimation} setLoadingAnimation={setLoadingAnimation} />}
      <div className={clsx(
        'bg-yanisallali-black min-w-screen min-h-dvh text-yanisallali-white font-gilroy relative flex flex-col',
        !route && "hidden"
      )}>
        <div className={clsx(
          'px-6 grow',
        )}>
          <header className='py-10 sticky top-0 left-0 w-full z-40 tablet:px-8'>
            <CustomNavBar />
          </header>
          <main>
              <Routes>
                {route && routes.map(
                  v=><Route path={v.path} element={route.element}/>
                )}
              </Routes>
          </main>
        </div>
        <footer className={clsx(
          'mt-48',
          !route && "hidden"
        )}>
          <Credits />
        </footer>
      </div>
    </div>
  );
}

export default App;
