import clsx from "clsx";
import SingleButton from "../../../buttons/SingleButton";
import { themeColors } from "../../../../settings";

export default function PortfolioCategories({ categories, setCategory, setAll, category, className }) {
    const activeClassName = "bg-yanisallali-white text-yanisallali-magenta";
    const reducedClassName = "py-1 px-3 mx-2 my-2";
    const activeIconClassName = "[&_path]:stroke-yanisallali-magenta";

    return (
        <div className={clsx(
            "flex tablet:justify-center items-center tablet:flex-wrap overflow-x-auto whitespace-nowrap scrollbar-hide -mx-6 tablet:mx-0",
            className
        )}>
            <SingleButton label={"Tout"} iconPath={"/images/icons/Layers.svg"} className={clsx(
                reducedClassName,
                !category && activeClassName 
            )} iconClassName={clsx(
                !category && activeIconClassName
            )} onClick={setAll} hoverIcon={false}/> 
            {categories.map(
                (v, index) => <SingleButton label={v.name} key={index} iconPath={v.acf.icon.value.url} onClick={() => {
                    setCategory(v);
                }} className={clsx(
                    reducedClassName,
                    (category && category.id === v.id) && activeClassName 
                )}
                iconClassName={clsx(
                    (category && category.id === v.id) && activeIconClassName
                )}
                hoverIcon={false}
                />
            )}
        </div>
    );
}
