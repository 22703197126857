import clsx from "clsx";

function GrayBox(props){
    return (
        <div className={clsx(
            "bg-[#272727] border border-yanisallali-1/3-white rounded py-4 px-6",
            props.className
        )}>
            {props.content}
        </div>
    )
}

export default GrayBox;