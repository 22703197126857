import { useEffect, useRef, useState } from "react";
import Heading from "../../../../utils/texts/Heading";
import he from "he";
import axios from "axios";
import { BASE_URL } from "../../../../../settings";
import CtaButton from "../../../../buttons/CtaButton";
import clsx from "clsx";
import { useHover } from "@uidotdev/usehooks";
import { Link } from "react-router-dom";

export default function PortfolioGridItem({ id, slug, title, categories_realisation, featured_media }) {
    const [image, setImage] = useState(null);
    const [category, setCategory] = useState(null);
    const [ref, hover] = useHover();
    const containerRef = useRef();

    async function getImage() {
        try {
            const response = await axios.get(`${BASE_URL}/media/${featured_media}`);
            setImage({
                "src": response.data.media_details.sizes.medium_large.source_url,
                "alt": response.data.alt_text
            });
        } catch (err) {
            console.log(err);
        }
    }

    async function getCategory() {
        const categories = [];
        for(let category_realisation of categories_realisation){
            try {
                const response = await axios.get(`${BASE_URL}/categories_realisation/${category_realisation}`);
                categories.push(response.data.name);
            } catch (err) {
                console.log(err);
            }
        }
        setCategory(categories.join(", "))
    }

    useEffect(() => {
        getImage();
        getCategory();
    }, [id]);

    return (
        <div ref={containerRef} className={clsx(
            "computer:h-[400px] computer:overflow-hidden transition-all duration-300 flex justify-center items-center",
        )}>
            <div className="border border-yanisallali-1/3-white p-5 rounded-xl bg-yanisallali-1/10-black w-full" ref={ref}>
                {image &&
                    <Link to={"/portfolio/projets/" + slug}><img src={image.src} alt={image.alt} className="w-full aspect-screen object-cover rounded-xl" /></Link>}
                <h3 className="mt-3">
                    <Heading
                        content={he.decode(title.rendered)}
                        fontSize={"text-semi-large tablet:text-thick"} />
                </h3>
                {category && <h4 className="tablet:text-large opacity-30 whitespace-nowrap">{category}</h4>}
                <div className={clsx(
                    "w-full overflow-hidden transition-all duration-300 computer:max-h-[0px]",
                    hover ? "computer:max-h-[60px]" : "computer:max-h-[0px]"
                )}>
                    <CtaButton
                        label={"Voir le projet"}
                        className={clsx(
                            "py-[0.25rem] w-full mt-3 duration-300",
                            hover ? "computer:scale-full" : "computer:scale-0"
                        )}
                        iconPath={"/images/icons/Arrow-right-circle.svg"}
                        iconClassName={"[&_svg]:w-[20px]"}
                        url={"/portfolio/projets/" + slug} />
                </div>
            </div>
        </div>
    )

}