import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL, themeColors } from "../../../settings";
import he from "he";
import Heading from "../../utils/texts/Heading";
import DecorativeButton from "../../buttons/DecorativeButton";
import { parse, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import OutilsGrid from "./OutilsGrid";
import { useMediaQuery } from "react-responsive";
import clsx from "clsx";
import ArrowScrollAnimation from "../Temporary/LandingSection/ArrowScrollAnimation";
import CtaGrid from "./CtaGrid";
import PortfolioGrid from "../Portfolio/PortfolioGrid";
import CtaButton from "../../buttons/CtaButton";
import ContactSection from "../Temporary/ContactSection";
import Credits from "../../footer/Credits";

function DesktopDisplay({ project, category, outils, className }) {
    return (
        <div className={clsx(
            "flex justify-between",
            className
        )}>
            <div>
                <h1>
                    <Heading
                        content={he.decode(project.title.rendered)}
                        fontSize={"text-larger tablet:text-extra-large"} />
                </h1>
                <p className="text-semi-more tablet:text-large mb-8 text-pretty max-w-[900px] mt-2">{project.acf.description}</p>
                {(project.acf.collaborations && project.acf.collaborations.activated == "true") &&
                    <p className="text-semi-more tablet:text-large text-pretty max-w-[900px]">
                        En collaboration avec :&nbsp;
                        {project.acf.collaborations.collaborators.map(
                            (v, index) => <>
                                <a href={v.collaborator.url} target={v.collaborator.target} className="underline">{v.collaborator.title}</a>
                                {index < project.acf.collaborations.collaborators.length - 1 && <span>, </span>}
                            </>
                        )}
                    </p>}
            </div>
            <div className="flex flex-col items-end">
                {category && <DecorativeButton
                    label={category.name}
                    iconPath={category.acf.icon.value.url}
                    className={"w-fit bg-yanisallali-white text-yanisallali-black [&_path]:stroke-yanisallali-black opacity-30"} />}
                <div className="capitalize text-semi-more tablet:text-large text-pretty opacity-30 mt-2">
                    {formatDateToFrench(project.acf.date)}
                </div>
                <OutilsGrid outils={outils} className={"mt-4"} />
            </div>
        </div>
    )
}

function TabletDisplay({ project, category, outils, className }) {
    return (
        <div className={clsx(
            "flex flex-col items-center",
            className
        )}>
            <h1 className="text-center">
                <Heading
                    content={he.decode(project.title.rendered)}
                    fontSize={"text-larger tablet:text-extra-large"} />
            </h1>
            <div className="flex items-center justify-center mt-6">
                {category && <DecorativeButton
                    label={category.name}
                    iconPath={category.acf.icon.value.url}
                    className={"w-fit bg-yanisallali-white text-yanisallali-black [&_path]:stroke-yanisallali-black opacity-30 mr-6"} />}
                <div className="capitalize text-large text-pretty opacity-30 mt-2 ml-6 text-center">
                    {formatDateToFrench(project.acf.date)}
                </div>
            </div>
            <div className="text-center mt-8">
                <p className="text-semi-more tablet:text-large mb-8 text-pretty max-w-[900px] mt-2">{project.acf.description}</p>
                {(project.acf.collaborations && project.acf.collaborations.activated == "true") &&
                    <p className="text-semi-more tablet:text-large text-pretty max-w-[900px]">
                        En collaboration avec :&nbsp;
                        {project.acf.collaborations.collaborators.map(
                            (v, index) => <>
                                <a href={v.collaborator.url} target={v.collaborator.target} className="underline">{v.collaborator.title}</a>
                                {index < project.acf.collaborations.collaborators.length - 1 && <span>, </span>}
                            </>
                        )}
                    </p>}
            </div>
            <OutilsGrid outils={outils} className={"mt-16"} />
        </div>
    )
}

function formatDateToFrench(baseDate) {
    const dateFormat = 'MM/yyyy'; // format of the input date string

    // Parsing the date string into a Date object
    const parsedDate = parse(baseDate, dateFormat, new Date(), { locale: fr });

    // Formatting the Date object into the desired string format
    const formattedDate = format(parsedDate, 'MMMM yyyy', { locale: fr });

    return formattedDate;
}

export default function Project({ setLoading }) {
    const params = useParams();
    const [slug,setSlug] = useState(null);
    const [project, setProject] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
    const [category, setCategory] = useState(null);
    const [outils, setOutils] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const [projects, setProjects] = useState([]);
    const ref = useRef();

    const isTablet = useMediaQuery({
        query: "(max-width: 800px)"
    });

    useEffect(() => {
        if (project && thumbnail && category && outils) {
            setLoading(false);
            scrollWindow();
        }
    }, [project, thumbnail, category, outils]);

    function scrollWindow() {
        if(ref.current){
            ref.current.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
        }
    }

    async function getProject() {
        try {
            const response = await axios.get(`${BASE_URL}/realisations?slug=${slug}`);
            if(response.data.length > 0){
                const data = response.data[0];
                setProject(data);
                getThumbnail(data.featured_media);
                getCategory(data.categories_realisation[0]);
                getOutils(data.outils);
                getProjects(data.categories_realisation[0]);
            } else {
                redirectError();
            }
        } catch (err) {
            redirectError();
        }
    }

    function redirectError(){
        setLoading(false);
        setTimeout(()=>{
            navigate("/404")
        },1000);
    }


    async function getThumbnail(id) {
        try {
            const response = await axios.get(`${BASE_URL}/media/${id}`);
            setThumbnail({
                "src": response.data.media_details.sizes.full.source_url,
                "alt": response.data.alt_text
            });
        } catch (err) {
            console.log(err);
        }
    }

    async function getCategory(id) {
        try {
            const response = await axios.get(`${BASE_URL}/categories_realisation/${id}`);
            setCategory(response.data);
        } catch (err) {
            console.log(err);
        }
    }

    async function getOutils(ids) {
        const res = [];
        for (let id of ids) {
            try {
                const response = await axios.get(`${BASE_URL}/outils/${id}`);
                res.push({
                    "src": response.data.acf.icon.sizes.thumbnail,
                    "alt": response.data.name
                });
            } catch (err) {
                console.log(err);
            }
        }
        setOutils(res);
    }

    async function getProjects(id) {
        try {
            const response = await axios.get(`${BASE_URL}/realisations?categories_realisation=${id}&limit=3`);
            setProjects(response.data);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (slug) {
            getProject();
        }
    }, [slug]);

    useEffect(() => {
        if (!slug) {
            setSlug(params.slug)
        } else {
            if (params.slug !== slug) {
                setTimeout(() => {
                    setSlug(params.slug)
                }, 2000);
            } else {
                scrollWindow();
            }
        }
    }, [params]);


    return (
        <>
            {project &&
                <div className="fixed w-full h-full top-0 left-0 overflow-y-scroll overflow-x-hidden bg-yanisallali-black" ref={ref}>
                    <div style={{
                        "background": `linear-gradient(to bottom,${project.acf.gradient_colors.color_1},${project.acf.gradient_colors.color_2},${themeColors.black})`
                    }}
                        className="pt-48 px-6">
                        <div className="max-w-[1300px] mx-auto">
                            <div className="w-full computer:text-more flex items-center justify-center [&>*]:mx-1 [&>*]:opacity-60 [&>*]:whitespace-nowrap [&>a]:underline [&>a]:transition-all [&>a:hover]:opacity-100 mb-8">
                                <Link to={"/portfolio"}>Portfolio</Link>
                                <span>&gt;</span>
                                {category && <Link to={"/portfolio"} onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/portfolio", {
                                        state: {
                                            category
                                        }
                                    })
                                }}>{category.name}</Link>}
                                <span>&gt;</span>
                                <Link to={location.pathname}>{he.decode(project.title.rendered)}</Link>
                            </div>
                            <div className="w-full">
                                {thumbnail && <img src={thumbnail.src} alt={thumbnail.alt} className="w-full aspect-[21/9] object-cover rounded-xl" />}
                            </div>
                            {isTablet ? <TabletDisplay project={project} category={category} outils={outils} className={"mt-8"} /> : <DesktopDisplay project={project} category={category} outils={outils} className={"mt-16"} />}
                            <div>
                                <div className="flex flex-col items-center mt-24 text-center">
                                    <h2 className="font-bold text-thick">En voir plus ?</h2>
                                    <ArrowScrollAnimation />
                                </div>
                                <CtaGrid links={project.acf.links} className={"mt-16"} />
                            </div>
                        </div>
                    </div>
                    <div className="px-6 mt-32">
                        <div className="max-w-[1300px] mx-auto">
                            <div className="flex flex-col items-center">
                                <h2 className="font-bold text-thick large-computer:text-larger text-center">
                                    <Heading
                                        content={[
                                            {
                                                "text": "Dans la même "
                                            },
                                            {
                                                "text": "catégorie",
                                                "highlight": "true"
                                            }
                                        ]} />
                                </h2>
                                <PortfolioGrid items={projects} className={"my-16 tablet:mt-12 computer:mt-0 mb-4"} />
                                <CtaButton label={"Voir tous les projets"} iconPath={"/images/icons/Arrow-up-right.svg"} className={"[&_svg]:w-[1.25rem]"} url={"/portfolio"} />
                            </div>
                            <div className="mt-32">
                                <h2 className="font-bold text-thick large-computer:text-larger text-center">Me contacter</h2>
                                <ContactSection />
                            </div>
                        </div>
                    </div>
                    <footer className="mt-32">
                        <Credits />
                    </footer>
                </div>}
        </>
    )
}