import clsx from "clsx";

function TextInput(props){
    const className = "w-full outline-none text-yanisallali-white bg-yanisallali-1/10-black border-yanisallali-1/3-white border rounded py-2 px-3 placeholder:opacity-50 focus:border-yanisallali-white focus:outline-none"
    return (
        props.textarea ? <textarea
        className={clsx(
            className,
            props.className
        )}
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.onChange}>{props.value}</textarea> : <input
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        required={props.required}
        className={clsx(
            className,
            props.className
        )}
        aria-required={props.required}/>
    )
}

export default TextInput;