import { useState } from "react";
import TextInput from "../../../../inputs/TextInput";
import SubmitButton from "../../../../buttons/SubmitButton";
import clsx from "clsx";
import axios from "axios";
import { CONTACT_URL } from "../../../../../settings";

function ContactForm(props){
    const [name,updateName] = useState("");
    const [email,updateEmail] = useState("");
    const [subject,updateSubject] = useState("");
    const [message,updateMessage] = useState("");
    const [submitable,setSubmitable] = useState(true);
    const inputClassname = "mb-4 text-semi-less tablet:text-base"
    async function handleSubmit(e){
        e.preventDefault();
        if(submitable){
            setSubmitable(false);
            let data = new FormData;
            data.append("name",name);
            data.append("email",email);
            data.append("subject",subject);
            data.append("message",message);
            e.target.reset();
            updateName("");
            updateEmail("");
            updateSubject("");
            updateMessage("");
            try{
                const response = await axios.post(`${CONTACT_URL}/contact`,data);
                props.onSuccess();
                setSubmitable(true);
            } catch(err){
                setSubmitable(true);
                console.log(err);
            }
        }
    }

    return (
        <form className="mt-6 flex flex-col h-full" onSubmit={handleSubmit} id="contact">
            <TextInput
            type="text"
            name="name"
            value={name}
            onChange={(e)=>{
                updateName(e.target.value)
            }}
            placeholder="Votre Nom et Prénom"
            required
            className={inputClassname}/>
            <TextInput
            type="email"
            name="email"
            value={email}
            onChange={(e)=>{
                updateEmail(e.target.value)
            }}
            placeholder="Votre Adresse E-mail"
            required
            className={inputClassname}/>
            <TextInput
            type="text"
            name="subject"
            value={subject}
            onChange={(e)=>{
                updateSubject(e.target.value)
            }}
            placeholder="Votre Sujet"
            required
            className={inputClassname}/>
             <TextInput
            type="text"
            name="message"
            value={message}
            onChange={(e)=>{
                updateMessage(e.target.value)
            }}
            placeholder="Écrivez votre message..."
            required
            className={clsx(
                inputClassname,
                "grow"
            )}
            textarea/>
            <SubmitButton
            label="Envoyer mon message"
            icon="Send"
            className="w-full computer:w-fit text-semi-less tablet:text-base"/>
        </form>
    )
}

export default ContactForm;